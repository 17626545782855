import React from 'react'
import Section from '../section/section'

const NGENsWay = () => {
  return <Section id="ngensway">
    <div className="container">

      <div className="row justify-content-center">
        <div className="col-lg-7">

          <div className="text-center">
            <h2 className="section-heading text-uppercase">NGEN's Way</h2>
            <h4 className="text-primary mb-4">SATISFACTION GUARANTEED</h4>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <p>If for any reason a consumer is not happy, you may offer to redo the service or refund the purchase price for that item or service.</p>
              <p>Then simply email us a copy of the redone or refunded service, and we'll replace your inventory x3! That's right, we'll give you 3 replacements for 1 product or service, because NGEN's Way is all about being happy!</p>
            </div>
          </div>
          <div className="row justify-content-center align-items-center mt-3 position-relative">
            <div className="col-auto">
              <h4 className="text-primary text-center">"IF YOU'RE HAPPY, WE'RE HAPPY."</h4>
            </div>
          </div>

        </div>
      </div>

      
    </div>
  </Section>
}

export default NGENsWay;