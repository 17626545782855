import React from 'react'
import { Link } from 'gatsby'
import * as styles from './header.module.scss'

const Header = ({ id }) => {
  return <header className={`${styles.header}`}>
    <div id={id} className={styles.anchor}></div>

    <div className={`${styles.headerContent}`}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col text-center">
                    <h1 className={styles.headerTitle}>The Next Generation</h1>
                    <p className={`${styles.headerText} mb-5`}>The future of Automotive Performance Chemicals</p>
                    <Link className="btn btn-lg btn-primary text-uppercase" to="/products">View Our Products</Link>
                </div>
            </div>
        </div>
    </div>
  </header>
}

export default Header;
