import React from "react"

import Layout from '../components/layout/layout'
import Header from '../components/header/header'
import AboutUs from '../components/about/about'
import NGENsWay from '../components/ngensway/ngensway'

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Header id="home" />

      <AboutUs />

      <NGENsWay />
    </Layout>
  )
}

export default IndexPage
